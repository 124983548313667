import React from "react"
import { Box } from "@mui/material"
import NavbarItem from "./navbar-item"
import * as navStyles from "../../styles/nav.module.css"

const Navbar = () => {
  return (
    <Box id={navStyles.container}>
      <NavbarItem
        title="Incoming Students"
        items={[
          "Enrollment",
          "Transfer",
          "The Basics",
          "My.ServiceHub",
          "Student Success",
        ]}
        slugs={[
          "/incoming-students/enrollment",
          "/incoming-students/transfer",
          "/incoming-students/basics",
          "/incoming-students/service-hub",
          "/incoming-students/success",
        ]}
      />
      <NavbarItem
        title="Financial"
        items={[
          "OSAP",
          "Financial Aid",
          "Medical",
          "Budgeting",
          "Free services",
        ]}
        slugs={[
          "/financial/osap",
          "/financial/aid",
          "/financial/medical",
          "/financial/budgeting",
          "/financial/free-stuff",
        ]}
      />
      <NavbarItem
        title="Housing & Residency"
        items={["Off-campus Housing", "Student Hostel", "Residency"]}
        slugs={["/housing/off-campus", "/housing/hostel", "/housing/residency"]}
      />
      <NavbarItem
        title="Academics"
        items={["Study Support", "Library", "Study Spaces", "Accommodation"]}
        slugs={[
          "/academics/support",
          "/academics/library",
          "/academics/study",
          "/academics/accommodation",
        ]}
      />
      <NavbarItem
        title="Career & Co-op"
        items={["Off-campus jobs", "On-campus jobs", "Co-op", "Volunteer"]}
        slugs={[
          "/career/off-campus",
          "/career/on-campus",
          "/career/co-op",
          "/career/volunteer",
        ]}
      />
      <NavbarItem
        title="Safety"
        items={["Walk safe", "C3SVS", "Hotlines", "TMU Security"]}
        slugs={["/safety/walk-safe", "/c3svs", "/hotlines", "/safety/security"]}
      />
      <NavbarItem
        title="Health"
        items={[
          "Hotlines",
          "C3SVS",
          "Medical Center",
          "Counseling",
          "Resources",
        ]}
        slugs={[
          "/hotlines",
          "/c3svs",
          "/health/medical-center",
          "/health/counselling",
          "/health/resources",
        ]}
      />
    </Box>
  )
}

export default Navbar
