// extracted by mini-css-extract-plugin
export var blueArrow = "inputs-module--blueArrow--f524a";
export var cardCallToAction = "inputs-module--cardCallToAction--138b9";
export var cardContent = "inputs-module--cardContent--0a17c";
export var cardDetails = "inputs-module--cardDetails--46777";
export var cardOrg = "inputs-module--cardOrg--7b117";
export var cardSummary = "inputs-module--cardSummary--99201";
export var cardTitle = "inputs-module--cardTitle--3847b";
export var eventsCard = "inputs-module--eventsCard--1e553";
export var grayArrow = "inputs-module--grayArrow--aca2a";
export var inlineInput = "inputs-module--inlineInput--e68cb";
export var linkBlock = "inputs-module--linkBlock--48cd4";
export var mainIcon = "inputs-module--mainIcon--79afe";
export var readMore = "inputs-module--readMore--26762";