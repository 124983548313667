import axios from "axios"

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.BASE_URL,
})

const getAll = async () => {
  const res = await instance.get("wizard")
  return res.data
}

const get = async id => {
  const res = await instance.get("wizard/item/" + id)
  return res.data
}

const getSearch = async query => {
  const res = await instance.get("wizard/search", { params: { q: query } })
  return res.data
}

const wizardService = {
  getAll,
  get,
  getSearch,
}

export default wizardService
