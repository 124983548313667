import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import WizardService from "../services/wizard"

const currentNodes = { nodes: null, currentNodes: null, searchNodes: null }
export const getAll = createAsyncThunk(
  "wizard/getAll",
  async (state, thunkAPI) => {
    try {
      const nodes = await WizardService.getAll()
      return { nodes: nodes.data }
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

export const get = createAsyncThunk("wizard/get", async (id, thunkAPI) => {
  try {
    const nodes = await WizardService.get(id)
    return { currentNodes: nodes.data }
  } catch (error) {
    return thunkAPI.rejectWithValue("Servers probably down")
  }
})

export const search = createAsyncThunk(
  "wizard/search",
  async (query, thunkAPI) => {
    try {
      const data = await WizardService.getSearch(query)
      return { searchNodes: data }
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

const initialState = currentNodes

const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  //TBH might be not needed in this case but not sure so keeping it here
  extraReducers: builder => {
    builder.addCase(getAll.fulfilled, (state, { payload }) => {
      state.nodes = payload.nodes
    })
    builder.addCase(getAll.rejected, (state, { payload }) => {
      state.nodes = null
    })
    builder.addCase(get.fulfilled, (state, { payload }) => {
      state.currentNodes = payload.currentNodes
      state.searchNodes = null
    })
    builder.addCase(get.rejected, (state, { payload }) => {
      state.currentNodes = null
      state.searchNodes = null
    })

    builder.addCase(search.fulfilled, (state, { payload }) => {
      state.searchNodes = payload.searchNodes
    })
    builder.addCase(search.rejected, (state, { payload }) => {
      state.searchNodes = null
    })
  },
})

const { reducer } = wizardSlice
export default reducer
