import axios from "axios"
const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.BASE_URL,
})

const login = async body => {
  const res = await instance.post("login", body)
  return res.data
}

const sessionCheck = async () => {
  const res = await instance.get("session-check")
  return res.data
}

const logout = async () => {
  const res = await instance.post("logout")
  return res.data
}

const authService = {
  login,
  logout,
  sessionCheck,
}

export default authService
