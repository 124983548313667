import React from "react"

import { TextField } from "@mui/material"

import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import * as styles from "../styles/inputs.module.css"

const InlineTextField = props => {
  const { hint, shortHint, startAdornment, endAdornment } = props
  const theme = useTheme()
  const screenSizeMD = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <TextField
      className={styles.inlineInput}
      variant="standard"
      placeholder={screenSizeMD ? hint : shortHint ?? ""}
      fullWidth

      InputProps={{
        disableUnderline: true,
        style: { fontWeight: "bold", color: "black" },
        startAdornment,
        endAdornment,
      }}
      {...props}
    />
  )
}
export default InlineTextField
