//Necessary libraries and components
import React from "react"
import { Typography, IconButton, Link, Box, Grid } from "@mui/material"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import YouTubeIcon from "@mui/icons-material/YouTube"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import MusicNoteIcon from "@mui/icons-material/MusicNote"
import * as subfooterStyles from "../../styles/subfooter.module.css"

import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { navigate } from "gatsby"
import { Twitter } from "@mui/icons-material"

const SubFooter = () => {
  const theme = useTheme()
  const screenSizeXS = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    // Box component from MUI to provide a container for footer
    <Box id={subfooterStyles.container}>
      <Grid container columnSpacing={0} columnGap={0} rowSpacing={1}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Toronto Metropolitan University
          </Typography>
        </Grid>
        {screenSizeXS && (
          <Grid className={subfooterStyles.right} item sm={6} xs={12}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              Follow Us
            </Typography>
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Typography sx={{ fontSize: "14px" }}>350 Victoria Street</Typography>
          <Typography sx={{ fontSize: "14px" }}>Toronto, ON M5B 2K3</Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Phone:
            <Link color={"inherit"} href="tel:+14169795000">
              416-979-5000
            </Link>
          </Typography>
        </Grid>
        {!screenSizeXS && (
          <Grid className={subfooterStyles.right} item sm={6} xs={12}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
              Follow Us
            </Typography>
          </Grid>
        )}
        <Grid className={subfooterStyles.right} item sm={6} xs={12}>
          <Box id={subfooterStyles.socialMedia}>
            <IconButton
              onClick={() => navigate("https://www.facebook.com/torontomet")}
              color="inherit"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate("https://www.instagram.com/torontomet")}
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate("https://twitter.com/torontomet")}
              color="inherit"
            >
              <Twitter />
            </IconButton>
            <IconButton
              onClick={() =>
                navigate(
                  "https://www.youtube.com/TorontoMetropolitanUniversity"
                )
              }
              color="inherit"
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                navigate(
                  "https://www.linkedin.com/company/torontometropolitanuniversity"
                )
              }
              color="inherit"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate("https://www.tiktok.com/@torontomet")}
              color="inherit"
            >
              <MusicNoteIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate("https://www.threads.net/@torontomet")}
              color="inherit"
            >
              @
            </IconButton>
          </Box>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Link
            href="https://www.torontomu.ca/contact/"
            color="inherit"
            sx={{ fontSize: "14px" }}
          >
            Directory
          </Link>
          <span style={{ marginLeft: "5px", marginRight: "5px" }}>/</span>
          <Link
            href="https://www.torontomu.ca/maps/"
            color="inherit"
            sx={{ fontSize: "14px" }}
          >
            {screenSizeXS ? "Maps and Directions" : "Maps"}
          </Link>
        </Grid>

        <Grid className={subfooterStyles.right} item sm={6} xs={6}>
          <Link
            href="https://www.torontomu.ca/careers/"
            color="inherit"
            sx={{ fontSize: "14px" }}
          >
            Careers
          </Link>
          <span style={{ marginLeft: "5px", marginRight: "5px" }}>/</span>
          <Link
            href="https://www.torontomu.ca/media/"
            color="inherit"
            sx={{ fontSize: "14px" }}
          >
            Media Room
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SubFooter
