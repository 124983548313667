//Necessary libraries and components
import React from "react"
import { Link, Box } from "@mui/material"
import * as footerStyles from "../../styles/footer.module.css"

const Footer = () => {
  return (
    // Box component from MUI to provide a container for footer
    <Box>
      <Box id={footerStyles.container}>
        <Link
          href="https://www.torontomu.ca/privacy/"
          color="inherit"
          sx={{ fontSize: "14px" }}
        >
          Privacy Policy
        </Link>
        <span style={{ marginLeft: "5px", marginRight: "5px" }}>/</span>
        <Link
          href="https://www.torontomu.ca/accessibility/"
          color="inherit"
          sx={{ fontSize: "14px" }}
        >
          Accessibility
        </Link>
        <span style={{ marginLeft: "5px", marginRight: "5px" }}>/</span>
        <Link
          href="https://www.torontomu.ca/terms-conditions/"
          color="inherit"
          sx={{ fontSize: "14px" }}
        >
          Terms & Conditions
        </Link>
      </Box>
    </Box>
  )
}

export default Footer
