import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import ScheduleService from "../services/schedule"

const scheduleItems = { schedule: null, assignment: null, importantDate: null }
export const getSchedule = createAsyncThunk(
  "schedule/get",
  async (state, thunkAPI) => {
    try {
      const data = await ScheduleService.getSchedule()
      return { schedule: data }
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

export const getAssignments = createAsyncThunk(
  "assignment/get",
  async (state, thunkAPI) => {
    try {
      const data = await ScheduleService.getAssigments()
      return { assignment: data }
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

export const getImportantDates = createAsyncThunk(
  "importantDate/get",
  async (state, thunkAPI) => {
    try {
      const data = await ScheduleService.getImportantDates()
      return { importantDate: data }
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

const initialState = scheduleItems

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  //TBH might be not needed in this case but not sure so keeping it here
  extraReducers: builder => {
    builder.addCase(getSchedule.fulfilled, (state, { payload }) => {
      state.schedule = payload.schedule
    })
    builder.addCase(getSchedule.rejected, (state, { payload }) => {
      state.schedule = null
    })
    builder.addCase(getAssignments.fulfilled, (state, { payload }) => {
      state.assignment = payload.assignment
    })
    builder.addCase(getAssignments.rejected, (state, { payload }) => {
      state.assignment = null
    })

    builder.addCase(getImportantDates.fulfilled, (state, { payload }) => {
      state.importantDate = payload.importantDate
    })
    builder.addCase(getImportantDates.rejected, (state, { payload }) => {
      state.importantDate = null
    })
  },
})

const { reducer } = scheduleSlice
export default reducer
