//Necessary libraries and components
import React from "react"
import {
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Divider,
  IconButton,
} from "@mui/material"
import { navigate } from "gatsby"
import DrawerItem from "./drawer-item"
import { CloseOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../redux/slices/auth"

const TMUDrawer = ({ portal, open, handleClose, handleOpen }) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const [navOptions, setNavOptions] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const handleClick = index => {
    let tmp = [false, false, false, false, false, false, false]
    tmp[index] = !navOptions[index]

    setNavOptions(tmp)
  }

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Box>
        <IconButton size="medium" sx={{ color: "white" }} onClick={handleClose}>
          <CloseOutlined className={"icon20"} />
        </IconButton>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/wizard")}>
              <ListItemText primary="TMU Wizard" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/credits")}>
              <ListItemText primary="Credits" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            {!portal && (
              <ListItemButton
                onClick={() => navigate(!isLoggedIn ? "/login" : "/portal")}
              >
                <ListItemText primary="my.torontomu" />
              </ListItemButton>
            )}
            {portal && (
              <ListItemButton onClick={() => navigate("/")}>
                <ListItemText primary="Home" />
              </ListItemButton>
            )}
          </ListItem>

          {isLoggedIn && (
            <ListItem disablePadding>
              <ListItemButton href="https://my.torontomu.ca/uPortal/p/rmsSelfServe.ctf2/max/render.uP?pP_section=personal">
                <ListItemText primary="Account Settings" />
              </ListItemButton>
            </ListItem>
          )}
          <Divider sx={{ borderColor: "rgba(255,255,255,0.25)" }} />
          <DrawerItem
            title="Incoming Students"
            items={[
              "Enrollment",
              "Transfer",
              "The Basics",
              "My.ServiceHub",
              "Student Success",
            ]}
            slugs={[
              "/incoming-students/enrollment",
              "/incoming-students/transfer",
              "/incoming-students/basics",
              "/incoming-students/service-hub",
              "/incoming-students/success",
            ]}
            open={navOptions[0]}
            onClick={() => handleClick(0)}
          />
          <DrawerItem
            title="Financial"
            items={[
              "OSAP",
              "Financial Aid",
              "Food",
              "Medical",
              "Budgeting",
              "Free services",
            ]}
            slugs={[
              "/financial/osap",
              "/financial/aid",
              "/financial/food",
              "/financial/medical",
              "/financial/budgeting",
              "/financial/free-stuff",
            ]}
            open={navOptions[1]}
            onClick={() => handleClick(1)}
          />
          <DrawerItem
            title="Housing & Residency"
            items={["Off-campus Housing", "Student Hostel", "Residency"]}
            slugs={[
              "/housing/off-campus",
              "/housing/hostel",
              "/housing/residency",
            ]}
            open={navOptions[2]}
            onClick={() => handleClick(2)}
          />
          <DrawerItem
            title="Academics"
            items={[
              "Study Support",
              "Library",
              "Study Spaces",
              "Accommodation",
            ]}
            slugs={[
              "/academics/support",
              "/academics/library",
              "/academics/study",
              "/academics/accomodation",
            ]}
            open={navOptions[3]}
            onClick={() => handleClick(3)}
          />
          <DrawerItem
            title="Career & Co-op"
            items={["Off-campus jobs", "On-campus jobs", "Co-op", "Volunteer"]}
            slugs={[
              "/career/off-campus",
              "/career/on-campus",
              "/career/co-op",
              "/career/volunteer",
            ]}
            open={navOptions[4]}
            onClick={() => handleClick(4)}
          />
          <DrawerItem
            title="Safety"
            items={["Walk safe", "C3SVS", "Hotlines", "TMU Security"]}
            slugs={[
              "/safety/walk-safe",
              "/c3svs",
              "/hotlines",
              "/safety/security",
            ]}
            open={navOptions[5]}
            onClick={() => handleClick(5)}
          />
          <DrawerItem
            title="Health"
            items={[
              "Hotlines",
              "C3SVS",
              "Medical Center",
              "Counseling",
              "Resources",
            ]}
            slugs={[
              "/hotlines",
              "/c3svs",
              "/health/medical-center",
              "/health/counseling",
              "/health/resources",
            ]}
            open={navOptions[6]}
            onClick={() => handleClick(6)}
          />
          {isLoggedIn && (
            <Divider sx={{ borderColor: "rgba(255,255,255,0.25)" }} />
          )}
          {isLoggedIn && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => dispatch(logout())}>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
    </SwipeableDrawer>
  )
}

export default TMUDrawer
