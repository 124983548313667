import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import AuthService from "../services/auth"

const user = null

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login({ username, password })
      return { user: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const sessionCheck = createAsyncThunk(
  "auth/sessionCheck",
  async (state, thunkAPI) => {
    try {
      const data = await AuthService.sessionCheck()
      if (data.isAuthenticated) return { user: data }
      return thunkAPI.rejectWithValue("Session expired or not found")
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout()
})

const initialState = user
  ? { isLoggedIn: true, loggedOut: false, user }
  : { isLoggedIn: false, loggedOut: false, user: null }

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true
      state.loggedOut = false
      state.user = payload.user
    })
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isLoggedIn = false
      state.user = null
    })
    builder.addCase(sessionCheck.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true
      state.loggedOut = false
      state.user = payload.user
    })
    builder.addCase(sessionCheck.rejected, (state, { payload }) => {
      state.isLoggedIn = false
      state.user = null
    })
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.isLoggedIn = false
      state.loggedOut = true
      state.user = null
    })
  },
})

const { reducer } = authSlice
export default reducer
