/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

// layout.js

//Necessary libraries and components
import React, { useEffect, useState } from "react"
import Header from "./header/header"
import Navbar from "./navbar/navbar"
import NavbarPortal from "./navbar/navbarPortal"
import Footer from "./footer/footer"
import SubFooter from "./footer/subfooter"
import "../styles/layout.css"
import { AppBar, Toolbar, Box, CssBaseline } from "@mui/material"
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

const theme = createTheme({
  palette: {
    primary: {
      main: "#031A31",
    },
    secondary: {
      main: "#FFDC00",
    },
    trinary: {
      main: "#400072",
    },
  },
  typography: {
    h6: {
      color: "black",
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
})

//Functional component called Layout, accepts children as props
const Layout = ({ children, pageContext }) => {
  const [showFooter, setShowFooter] = useState(false)
  const screenSizeSM = useMediaQuery("(min-width:700px)")
  useEffect(() => {
    if (!showFooter) setShowFooter(true)
  }, [showFooter])
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box id={"layoutContainer"}>
          <CssBaseline />
          <AppBar position="static" color="default" variant="dense">
            <Toolbar
              disableGutters={true}
              sx={{ backgroundColor: "white" }}
              variant="dense"
            >
              <Header portal={pageContext.portal} />
            </Toolbar>
            {screenSizeSM && (
              <Toolbar
                disableGutters={true}
                sx={{ backgroundColor: "white" }}
                variant="dense"
              >
                {!pageContext.portal && <Navbar />}
                {pageContext.portal && <NavbarPortal />}
              </Toolbar>
            )}
          </AppBar>

          {/* Main content */}
          <Box sx={{ flexGrow: 1, padding: "0px 4%" }}>{children}</Box>
          {showFooter && (
            <Box>
              <SubFooter />
              <Footer />
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Layout
