import React from "react"
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import * as drawerStyles from "../../styles/nav.module.css"
import { navigate } from "gatsby"

const DrawerItem = ({ title, items, slugs, open, onClick }) => {
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton className={drawerStyles.dropdown}>
          <ListItemText
            onClick={() =>
              navigate(
                "/" + title.split(" &")[0].replaceAll(" ", "-").toLowerCase()
              )
            }
            primary={title}
          />
          {open ? (
            <ExpandLess
              className={drawerStyles.arrow}
              fontSize="medium"
              onClick={onClick}
            />
          ) : (
            <ExpandMore
              className={drawerStyles.arrow}
              fontSize="medium"
              onClick={onClick}
            />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <ListItemButton
              className={drawerStyles.subButton}
              key={index}
              onClick={() => navigate(slugs[index])}
              sx={{ pl: 4 }}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default DrawerItem
