import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import MapService from "../services/map"

const currentNode = { nodes: null, currentNode: null }
export const getAll = createAsyncThunk(
  "map/getAll",
  async (state, thunkAPI) => {
    try {
      const data = await MapService.getAll()
      return { nodes: data }
    } catch (error) {
      return thunkAPI.rejectWithValue("Servers probably down")
    }
  }
)

export const get = createAsyncThunk("map/get", async (tag, thunkAPI) => {
  try {
    const data = await MapService.get(tag)
    return { currentNode: data }
  } catch (error) {
    return thunkAPI.rejectWithValue("Servers probably down")
  }
})


const initialState = currentNode

const mapSlice = createSlice({
  name: "map",
  initialState,
  //TBH might be not needed in this case but not sure so keeping it here
  extraReducers: builder => {
    builder.addCase(getAll.fulfilled, (state, { payload }) => {
      state.nodes = payload.nodes
    })
    builder.addCase(getAll.rejected, (state, { payload }) => {
      state.nodes = null
    })
    builder.addCase(get.fulfilled, (state, { payload }) => {
      state.currentNode = payload.currentNode
      state.searchNodes = null
    })
    builder.addCase(get.rejected, (state, { payload }) => {
      state.currentNode = null
      state.searchNodes = null
    })
  },
})

const { reducer } = mapSlice
export default reducer
