import React, { useState } from "react"
import { Link, navigate } from "gatsby"

import {
  Typography,
  IconButton,
  Box,
  Grid,
  Menu,
  MenuItem,
  InputAdornment,
  Divider,
} from "@mui/material"
import logo from "/static/TMU-rgb.png"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import { ArrowDownward, Person } from "@mui/icons-material"
import InlineTextField from "../inline-text-field"

import useMediaQuery from "@mui/material/useMediaQuery"
import TMUDrawer from "../drawer/tmu-drawer"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../redux/slices/auth"
import * as headerStyles from "../../styles/header.module.css"

const Header = ({ portal }) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const screenSizeSM = useMediaQuery("(min-width:700px)")
  const screenSizeMD = useMediaQuery("(min-width:900px)")
  const [anchorEl, setAnchorEl] = useState(null) // State for dropdown menu position
  const [menuOpen, setMenuOpen] = useState(false) // Explicit state for menu visibility
  const [search, setSearch] = useState("")
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }
  const handleRedirectAndClose = (path) => {
    navigate(path)
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleLogoutClose = () => {
    dispatch(logout())
    setAnchorEl(null)
    setMenuOpen(false)
  }
  return (
    <Box id={headerStyles.container}>
      <Grid container spacing={1}>
        <Grid item id={headerStyles.left} lg={2.5} md={3} sm={5} xs={3}>
          <Box onClick={() => navigate("/")}>
            <img
              src={logo}
              alt="Toronto Metropolitan University Logo"
              id={headerStyles.logo}
            />
          </Box>
          <Box sx={{ flexDirection: "column", lineHeight: 0.5 }}>
            <Typography variant="h6" onClick={() => navigate("/")}>
              Resource
            </Typography>
            <Typography variant="p" onClick={() => navigate("/")}>
              Center
            </Typography>
          </Box>
        </Grid>
        <Grid item id={headerStyles.middle} lg={6.5} md={5} sm={6} xs={7}>
          <Box minWidth={screenSizeMD ? "400px" : "0px"}>
            {/* <InlineTextField
              hint="Search Resources and Services"
              shortHint="Search Resources"
              value={search}
              onChange={event => setSearch(event.target.value)}
              endAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            /> */}
          </Box>
        </Grid>
        <Grid id={headerStyles.right} item lg={3} md={4} sm={1} xs={2}>
          <Box mx={1} sx={{borderBottom:"4px solid #400072"}}  >
            <Typography
              to={"/wizard"}
              component={Link}
              mx={1}
              sx={{ color: "#400072",textDecoration:"none" }}
              variant="h6"
            >
              TMU Wizard
            </Typography>
          </Box>
          <IconButton

            id={
              !isLoggedIn ? headerStyles.dropdown : headerStyles.loginDropdown
            }
            size="medium"
            onClick={handleMenuOpen}
          >
            {menuOpen ? (
              <Box>
                <CloseIcon className={!isLoggedIn ? "icon30" : "icon20"} />
                {isLoggedIn && (
                  <ArrowDownward
                    sx={{ color: "#400072", width: "15px", height: "15px" }}
                  />
                )}
              </Box>
            ) : !isLoggedIn ? (
              <MenuIcon className={"icon30"} />
            ) : (
              <Box>
                <Person className={"icon20"} />
                <ArrowDownward sx={{ width: "15px", height: "15px" }} />
              </Box>
            )}
          </IconButton>
          {screenSizeSM && (
            <Menu
              id={headerStyles.menu}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {!portal && (
                <MenuItem
                  onClick={() => navigate(!isLoggedIn ? "/login" : "/portal")}
                >
                  my.torontomu
                </MenuItem>
              )}
              {portal && (
                <MenuItem onClick={() => handleRedirectAndClose("/")}>Home</MenuItem>
              )}
              {isLoggedIn && (
                <MenuItem
                  onClick={() =>
                    navigate(
                      "https://my.torontomu.ca/uPortal/p/rmsSelfServe.ctf2/max/render.uP?pP_section=personal"
                    )
                  }
                >
                  Account Settings
                </MenuItem>
              )}
              {isLoggedIn && <Divider />}
              <MenuItem
                onClick={() => handleRedirectAndClose("/wizard")}
                id={headerStyles.menuWizard}
              >
                TMU Wizard
              </MenuItem>
              <MenuItem
                onClick={() => navigate("https://www.torontomu.ca/admissions/")}
              >
                Apply to TMU
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate("https://www.torontomu.ca/admissions/visits-tours/")
                }
              >
                Visit TMU
              </MenuItem>
              <MenuItem
                onClick={() => navigate("https://www.torontomu.ca/giving/")}
              >
                Donate to TMU
              </MenuItem>
              <MenuItem
                onClick={() => handleRedirectAndClose("/credits")}
              >
                Credits
              </MenuItem>
              {isLoggedIn && <Divider />}
              {isLoggedIn && (
                <MenuItem onClick={handleLogoutClose}>Logout</MenuItem>
              )}
            </Menu>
          )}
          {!screenSizeSM && (
            <TMUDrawer
              portal={portal}
              open={menuOpen}
              handleClose={handleMenuClose}
              handleOpen={handleMenuOpen}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
