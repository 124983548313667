import React, { useState } from "react"
import { Button, Menu, MenuItem, Box, Typography } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import * as navStyles from "../../styles/nav.module.css"
import { navigate } from "gatsby"

const NavbarItem = ({ title, items, slugs, portal, link }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget.parentNode)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleRedirectAndClose = (path) => {
    navigate(path)
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        endIcon={
          !portal &&
          (Boolean(anchorEl) ? (
            <ArrowDropUpIcon onClick={handleClick} />
          ) : (
            <ArrowDropDownIcon onClick={handleClick} />
          ))
        }
        color="inherit"
        sx={{ color: "#ffffff", fontSize: "16px" }}
      >
        <Typography
          color={"inherit"}
          onClick={() =>
            navigate(
              link
                ? link
                : "/" + title.split(" &")[0].replaceAll(" ", "-").toLowerCase()
            )
          }
          variant="p"
          className={navStyles.text}
        >
          {title}
        </Typography>
      </Button>
      {!portal && (
        <Menu
          className={navStyles.menu}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {items.map((item, index) => (
            <MenuItem key={index} onClick={() => handleRedirectAndClose(slugs[index])}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  )
}

export default NavbarItem
