import axios from "axios"
const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.BASE_URL,
})

const getSchedule = async () => {
  const res = await instance.get("schedule")
  return res.data
}

const getAssigments = async id => {
  const res = await instance.get("assignment")
  return res.data
}

const getImportantDates = async () => {
  const res = await instance.get("important-dates")
  return res.data
}

const scheduleService = {
  getSchedule,
  getAssigments,
  getImportantDates,
}

export default scheduleService
