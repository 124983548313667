import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./slices/auth"
import scheduleReducer from "./slices/schedule"
import wizardReducer from "./slices/wizard"
import mapReducer from "./slices/map"
export default configureStore({
  reducer: {
    auth: authReducer,
    wizard: wizardReducer,
    schedule: scheduleReducer,
    map: mapReducer,
  },
})
