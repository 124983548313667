import axios from "axios"

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.BASE_URL,
})

const getAll = async () => {
  const res = await instance.get("map")
  return res.data
}

const get = async tag => {
  const res = await instance.get("map/" + tag)
  return res.data
}

const mapService = {
  getAll,
  get
}

export default mapService
